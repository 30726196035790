<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input"  v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="
              (dialogVisible = true), (currUser = multipleSelectionPres[0])
            "
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].status == 1
            "
            >审核
          </el-button>
        </div>
        <div class="search-container-fn-input"  v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="delItem(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1 && multipleSelectionPres[0].status != 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input"  v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input"  v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'image' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'status_d' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.status_d)
                "
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in examineOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
    >
    </editTableHead>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      :append-to-body="true"
    >
      <div class="edit-dialog">
        <div class="flex">
          <div
            class="flex"
            style="height: 30px;margin-left: 10px;font-weight: 400;"
          >
            <span>通过</span>
            <div
              :class="passReview == 2 ? 'pass-review' : ''"
              class="review"
              @click="passReview = 2"
            ></div>
          </div>
          <div
            class="flex"
            style="height: 30px;margin-left: 30px;font-weight: 400;"
          >
            <span>拒绝</span>
            <div
              :class="passReview == 3 ? 'pass-review' : ''"
              class="review"
              @click="passReview = 3"
            ></div>
          </div>
        </div>
        <div>
          <span
            style="font-size: 14px;font-weight: bold;margin-left: 10px;color: #606266;"
            >拒绝原因</span
          >
          <el-input
            :disabled="passReview == 2"
            type="textarea"
            v-model="examine_opinion"
            style="margin-top: 10px;"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini">取 消</el-button>
        <el-button type="primary" @click="saveReviewed" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="dilog">
      <el-dialog
        :title="info.type == 1 ? '文章内容详情' : '视频内容详情'"
        :visible.sync="dialogVisible1"
        width="60%"
        :before-close="handleClose1"
      >
        <div class="clm">
          <div style="font-size:24px;font-weight:bold;text-align: center;">
            标题: {{ info.title }}
          </div>
          <div style="margin:20px 0;">
            <span style="font-weight:bold" v-if="info.type == 1">内容:</span>
            <div v-html="info.content"></div>
          </div>
          <div
            v-if="info.type == 2"
            style="display: flex;width:100%;justify-content: center;"
          >
            <video
              id="media"
              class="media"
              height="400"
              width="800"
              controls
              :src="info.video"
            ></video>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  doctorContent,
  doctorContentDel,
  doctorContentExamine,
  doctorContentDetail,
} from "@/api/audit/doctor";
import Pagination from "@/components/Pagination";
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { mapState } from 'vuex';
export default {
  name: "reviewContent",
  components: {
    Pagination,
    editTableHead,
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'reviewContent',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "doctor_name",
          isSearch: false,
        },
        {
          name: "status_d",
          isSearch: false,
        }
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      info: {},
      currUser: {},
      examine_opinion: "",
      dialogVisible: false,
      dialogVisible1: false,
      examineOptions: [
        {
          label: "待审核",
          value: 1,
        },
        {
          label: "已审核",
          value: 2,
        },
        {
          label: "已拒绝",
          value: 3,
        },
      ],
      statusOptions: [
        {
          label: "文章",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
      ],
      tableData: [],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      listLoading: false,
      fullscreenLoading: false,
      searchParams: {},
      total: 0,
      passReview: 2,
      rules: {
        examine: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: "change",
          },
        ],
      },
    };
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  computed: {
    getExamineName() {
      return (status) => {
        let curExamine = this.examineOptions.find((v) => v.value == status);
        return curExamine ? curExamine.label : "";
      };
    },
    getSuperviseName() {
      return (status) => {
        let curSupervise = this.statusOptions.find((v) => v.value == status);
        return curSupervise ? curSupervise.label : "";
      };
    },
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "reviewContent",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.reviewContent;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getInfo(id) {
      console.log(id);
      let params = {
        id: id,
      };
      doctorContentDetail(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.info = {
              ..._data,
            };
            this.dialogVisible1 = true;
          }
          if (this.info.depart_id) {
            this.info.depart_id = Number(this.info.depart_id);
          }
          this.fullscreenLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line no-undef
          Message({
            message: error.message,
            type: "error",
          });
          this.fullscreenLoading = false;
        });
    },
    saveReviewed() {
      this.fullscreenLoading = true;
      if (this.passReview == 3 && !this.examine_opinion) {
        this.$message({
          message: "请填写拒绝原因",
          type: "error",
        });
        return;
      }
      if (this.passReview == 2) {
        this.examine_opinion = "";
      }
      console.log(this);
      let data = {
        id: this.currUser.id,
        status: this.passReview,
        examine_opinion: this.examine_opinion,
      };
      doctorContentExamine(data).then((res) => {
        if (res.code == 200) {
          if (this.passReview == 2) {
            this.$message({
              message: "审核通过",
              type: "success",
            });
          } else {
            this.$message({
              message: "审核已拒绝",
              type: "success",
            });
          }
          this.getList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.dialogVisible = false;
        this.fullscreenLoading = false;
      });
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.getList();
    },
    delItem(row) {
      console.log(row, "删除");

      //确定提示框
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          doctorContentDel({ id: row.id })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList() {
      this.listLoading = true;
      this.fullscreenLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.type = 1;
      params.page_size = this.listQuery.limit;
      doctorContent(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.total = res.data.total;
            this.listQuery.limit = Number(res.data.per_page);
            this.fullscreenLoading = false;
            this.listLoading = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.pass-review {
  width: 15px;
  height: 15px;
  background-color: #2362fb;
  border: 1px solid #dfe4ed;
  margin-left: 8px;
}
.edit-dialog {
  min-height: 20vh;
  max-height: 40vh;
  overflow: auto;

  .ipt {
    width: 80%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 80%;
  }
}

.review {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #dfe4ed;
  margin-left: 8px;
}
.clm {
  display: flex;
  flex-direction: column;
}
.dilog {
 ::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
  }
}
.media {
  background-color: black;
}
</style>
